<template>
  <div class="page_header">
      <a-breadcrumb>
      <a-breadcrumb-item v-for="(item,index) in pageData" :key="index">
        <router-link v-if="item.path!=''" :to="`${item.path}`">{{item.breadcrumbName }}</router-link>
        <span v-else>{{item.breadcrumbName }}</span>
      </a-breadcrumb-item>
    </a-breadcrumb>
  </div>
</template>
<script>
export default {
  name:'page'
  ,data(){
     return{
      
    }
  },
  props:['details'],
  computed:{
    pageData(){
      let obj=[{
          breadcrumbName: '授课证',
          path:"/VCertificate"
        }]
        if(this.details!=null){
          if(this.details.courseName){
            let id=this.details.courseId;
              obj.push({'breadcrumbName':this.details.courseName,'path':`/VCertiDetails?id=${id}`});
          }
          obj.push({'breadcrumbName':this.details.name,'path':''});
        }
      return obj;
    }
  },
  methods:{
   
  }
}
</script>
<style lang="scss" scoped>
.page_header{
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 10px 0 20px 0;
  &::after{
          content:"";
          width: 97.4%;
          position: absolute;
          bottom: -4px;
          left: 0;
          border: 1px solid #f6f6f6;
        }
}
</style>